/* eslint-disable */

const getDiscountPerItem = (cartItem) => {
  const priceToConsider = cartItem?.price?.discounted ?? cartItem?.price;
  return Math.abs(
    (priceToConsider?.value?.centAmount ?? 0) * cartItem?.quantity -
      cartItem.totalPrice?.centAmount
  );
};

export const calcCartDiscount = (cartItems) => {
  return cartItems.reduce((prevValue, nextItem) => {
    return prevValue + getDiscountPerItem(nextItem);
  }, 0);
};

export const calcCartDiscountCustomLineItems = (customLineItems) => {
  return customLineItems.reduce((prevValue, nextItem) => {
    return (
      prevValue +
      Math.abs(nextItem.totalPrice?.centAmount - nextItem.money?.centAmount)
    );
  }, 0);
};

const recalcIncludedDiscountPerQuantity = (includedDiscounts, quantity) => {
  return includedDiscounts.map((inclDisc) => {
    const copyInclDisc = { ...inclDisc };
    copyInclDisc.discountedAmount.centAmount =
      inclDisc.discountedAmount.centAmount * quantity;
    return copyInclDisc;
  });
};

const filterIncludedDiscounts = (cartItems) => {
  return cartItems.reduce((prevValue, nextItem) => {
    if (
      nextItem.discountedPricePerQuantity &&
      nextItem.discountedPricePerQuantity.length
    ) {
      const incDiscsReduction = nextItem.discountedPricePerQuantity.reduce(
        (prevValPerQ, nextDiscPerQ) => {
          if (
            nextDiscPerQ.discountedPrice.includedDiscounts &&
            nextDiscPerQ.discountedPrice.includedDiscounts.length
          ) {
            const calcDiscountByQuantity = recalcIncludedDiscountPerQuantity(
              nextDiscPerQ.discountedPrice.includedDiscounts,
              nextDiscPerQ.quantity
            );
            prevValPerQ.push(...calcDiscountByQuantity);
            return prevValPerQ;
          }
          return prevValPerQ;
        },
        []
      );
      prevValue.push(...incDiscsReduction);
    }
    return prevValue;
  }, []);
};

const filterIncludedDiscountsCustomLineItems = (customLineItems) => {
  return customLineItems.reduce((prevValue, nextItem) => {
    if (
      nextItem.discountedPricePerQuantity &&
      nextItem.discountedPricePerQuantity.length
    ) {
      const incDiscsReduction = nextItem.discountedPricePerQuantity.reduce(
        (prevValPerQ, nextDiscPerQ) => {
          if (
            nextDiscPerQ.discountedPrice.includedDiscounts &&
            nextDiscPerQ.discountedPrice.includedDiscounts.length
          ) {
            const calcDiscountByQuantity = recalcIncludedDiscountPerQuantity(
              nextDiscPerQ.discountedPrice.includedDiscounts,
              nextDiscPerQ.quantity
            );
            prevValPerQ.push(...calcDiscountByQuantity);
            return prevValPerQ;
          }
          return prevValPerQ;
        },
        []
      );
      prevValue.push(...incDiscsReduction);
    }
    return prevValue;
  }, []);
};

const reduceIncludedDiscounts = async (discounts) => {
  return discounts.reduce((prevValue, nextDiscount) => {
    if (!prevValue[nextDiscount.discount.id]) {
      prevValue[nextDiscount.discount.id] =
        nextDiscount.discountedAmount.centAmount;
    } else {
      prevValue[nextDiscount.discount.id] +=
        nextDiscount.discountedAmount.centAmount;
    }
    return prevValue;
  }, {});
};

const reduceTotalDiscounts = (discounts) => {
  return discounts.reduce((acc, item) => ({
    [item.discount.id]: item.discountedAmount.centAmount
  }), {});
}

export const processDiscountCodes = async (cartItems, customLineItems, discountsOnTotalPrice = []) => {
  const filteredDiscs = filterIncludedDiscounts(cartItems);
  const reducedDiscs = await reduceIncludedDiscounts(filteredDiscs);

  const filteredDiscsCustomLineItems =
    filterIncludedDiscountsCustomLineItems(customLineItems);
  const reduceDiscsCustom = await reduceIncludedDiscounts(
    filteredDiscsCustomLineItems
  );

  const {includedDiscounts = []} = discountsOnTotalPrice;
  const totalDiscounts = reduceTotalDiscounts(includedDiscounts);
  const allReduceDiscs = { ...reducedDiscs, ...reduceDiscsCustom, ...totalDiscounts };

  const accumulatedCodes = [];
  const lastCode = await Object.keys(allReduceDiscs).reduce(
    async (prevPromise, nextDiscKey) => {
      const prevRes = await prevPromise;
      if (prevRes && prevRes.code) {
        accumulatedCodes.push(prevRes);
      }

      const resCodeData = await fetchCartDiscountById(nextDiscKey);
      return {
        code: resCodeData.key,
        discount: allReduceDiscs[nextDiscKey] / 100,
        id: nextDiscKey,
      };
    },
    Promise.resolve()
  );
  if (lastCode && lastCode.code) {
    accumulatedCodes.push(lastCode);
  }

  return accumulatedCodes;
};

export const fetchCartDiscountById = async (discountId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ECOMMERCE_BASE_URL}/cart-discount/${discountId}`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }
    );
    const codeData = response.json();
    return codeData ?? null;
  } catch (error) {
    return null;
  }
};

export const fetchDiscountCodeById = async (discountId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ECOMMERCE_BASE_URL}/discount-code/${discountId}`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
      }
    );
    const codeData = response.json();
    return codeData ?? null;
  } catch (error) {
    return null;
  }
};

export const computeDiscountCodeToRemove = async (
  discountCodes,
  cartDiscountId
) => {
  if (discountCodes && discountCodes.length > 0) {
    const discountCodeList = [];
    const lastCode = await discountCodes.reduce(
      async (prevPromise, nextCode) => {
        const prevRes = await prevPromise;
        if (prevRes && prevRes.id) {
          discountCodeList.push(prevRes);
        }

        return await fetchDiscountCodeById(nextCode.discountCode.id);
      },
      Promise.resolve()
    );
    if (lastCode && lastCode.id) {
      discountCodeList.push(lastCode);
    }
    let codeToRemove = null;
    discountCodeList.forEach((discCodeData) => {
      const foundCartCode = discCodeData.cartDiscounts.find(
        (dCodeItem) => dCodeItem.id === cartDiscountId
      );
      if (foundCartCode) {
        codeToRemove = discCodeData;
      }
    });
    return codeToRemove.id ?? null;
  }
  return null;
};
